import React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import ProfilePhoto from "../assets/square_profile.png";

import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Avatar,
  Typography,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      margin: "auto",
    },
    container: {
      textAlign: "center",
    },
    links: {
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
      "& a": {
        textDecoration: "none !important",
        transitionDuration: "0.5s",
      },
      "& a:hover": {
        color: "#1abc9c",
      },
      marginTop: theme.spacing(2),
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      color: "#666",
    },
  })
);

const MainPage = () => {
  const classes = useStyles();
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "90vh" }}
    >
      <Grid item xs={12} className={classes.container}>
        <Avatar
          alt="Profile Photo"
          src={ProfilePhoto}
          className={classes.avatar}
        />

        <Typography variant="h5" className={classes.title}>
          Yusuf Koca
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          Javascript Developer
        </Typography>
        <Divider variant="middle" />
        <Grid container alignItems="center" className={classes.links}>
          <Link href="http://kodumunblogu.herokuapp.com/" color="inherit">
            Blog
          </Link>
          <Divider orientation="vertical" flexItem />
          {/*
            <Link href="/projects" color="inherit">
                  Projects
                </Link>

          <Divider orientation="vertical" flexItem />
             */}
          <Link href="https://yusufkoca.github.io/" color="inherit">
            About Me
          </Link>
          <Divider orientation="vertical" flexItem />
          <Link href="https://github.com/yusufkoca" color="inherit">
            Github
          </Link>
          <Divider orientation="vertical" flexItem />
          <Link href="https://www.linkedin.com/in/ysfkoca/" color="inherit">
            Linkedin
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainPage;
