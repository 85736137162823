import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import withTracker from "./utils/withTracker";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={withTracker(MainPage, {
            /* additional attributes */
          })}
        />
      </Switch>
    </BrowserRouter>
  );
}
